.filter-body{
    padding: 50px 15px;
    align-items: left;
}

.flsrch{
    border: 2px solid crimson;
    border-radius: 5px;
    height: 40px;
    width: 200px;
    outline: 0;
    padding: 10px;
    font-size: 20px;
    color: gray;
    margin-bottom: 30px;
}

.flr-br-line{
    border-bottom: 0.5px solid rgb(207, 205, 205);
}

.filter-brand h3{
    margin: 20px 0 20px 0;
}

.fltr-grp{
    line-height: 40px;
    display: flex;
    align-items: center;
}

.fltr-grp input{
    margin-right: 20px;
    width: 25px;
    height: 25px;
    border: 2px solid gray;
    outline: 0;
}

.fltr-grp label{
    font-size: 18px;
    font-weight: 600;
}