.item_body{
    padding: 50px 50px 50px 0px;
    display: flex;
}

.fltline{
    border-right: 0.5px solid rgb(221, 220, 220);
}

.type-grp{
    margin-bottom: 40px;
    padding-left: 50px;
}

.item_grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px 50px;
    margin: auto;
    padding-left: 50px;
}

.item_list{
    width: 250px;
    min-height: 220px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 0 12px 2px rgb(0 0 0 / 10%);
    padding: 20px;
    text-align: center;
}

.item_img{
    width: 220px;
    height: 180px;
    margin-bottom: 20px;
}

.item-logo{
    width: 220px;
    height: 180px;
    margin-bottom: 20px;
}

.item-name{
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
}

.item-code{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: gray;
}

.item-brand{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.item-rate{
    font-size: 20px;
    font-weight: 600;
    color: crimson;
}

.type-select-lable{
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
}

select{
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1px 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: 0;
}

select::-ms-expand {
    display: none;
  }

  .type-select {
    width: 100%;
    min-width: 350px;
    max-width: 50px;
    border: 1px solid crimson;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    color: gray;
    cursor: pointer;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }


@media (min-width: 320px) and (max-width: 650px){
    
    .item_grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px 50px;
        margin: auto;
        padding-left: 0px;
    }

    .item_body {
        padding: 0px;
        display: flex;
        margin: auto;
    }
}