.headerbar {
  min-height: 35px;
  background-color: lightgrey;
  padding: 10px 0px;
}

.headerbar h2 {
  text-align: center;
  font-size: 15px;
  font-family: Roboto Mono;
  color: crimson;
}

.headerbar1 {
  min-height: 65px;
  background-color: #ffffff;
  padding: 5px 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerbar2 {
  min-height: 55px;
  background-color: lightgrey;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-div {
  width: 250px;
  min-height: 35px;
  background-color: crimson;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.menu-bar {
    background-color: #ffffff;
  z-index: 1;
  position: absolute;
  top: 35px;
  left: 0px;
  width: 250px;
  box-shadow: 0 0 12px 2px rgb(0 0 0 / 10%);
}

.menu-li{
    text-decoration: none;
    list-style: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    border-bottom: 1px solid rgb(219, 217, 217);
    padding: 10px;
}

.menu-div h3 {
  color: #ffffff;
  font-size: 22px;
}

.biogenelogo {
  max-width: 200px;
  width: 100%;
}

.headerright {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.signdiv {
  margin-right: 20px;
  text-align: end;
  position: relative;
}

.signdivp {
  font-family: Roboto Mono;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  color: gray;
}

.headsearch {
  /* max-width: 420px;
    width: 100%;
    min-height: 40px;
    background-color: #FFFFFF;
    border-radius: 50px;
    border: 1px solid crimson; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.signpop {
  width: 150px;
  min-height: 120px;
  background-color: #ffffff;
  padding: 10px;
  position: absolute;
  left: -70px;
  z-index: 1;
  box-shadow: 0 2px 8px 0 black;
}

.signpop1 {
  text-align: left;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.search-form {
  display: flex;
}

.hsearch1 {
  appearance: none;
  border-radius: 50px;
  width: 650px;
  min-height: 40px;
  position: relative;
  border: 2px solid crimson;
  padding: 0 40px 0 20px;
  outline: 0;
  font-size: 18px;
  font-weight: 400;
}

.hsearchicon {
  background-image: url("../../images/search_FILL0_wght600_GRAD200_opsz48.svg");
  width: 40px;
  height: 38px;
  background-color: crimson;
  border-radius: 0px 50px 50px 0px;
  background-size: 40px;
  cursor: pointer;
  margin-left: -40px;
  z-index: 1;
}



@media (min-width: 320px) and (max-width: 650px) {
    .hsearch1 {
        appearance: none;
        border-radius: 50px;
        width: 300px;
        min-height: 40px;
        position: relative;
        border: 2px solid crimson;
        padding: 0 40px 0 20px;
        outline: 0;
        font-size: 18px;
        font-weight: 400;
        margin: 20px 0;
      }

      .hsearchicon {
        margin: 20px 0 20px -40px;
    }

.headerbar1 {
    min-height: 65px;
    background-color: #ffffff;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.biogenelogo {
    max-width: 120px;
    width: 100%;
}

.headerbar2 {
    min-height: 55px;
    background-color: lightgrey;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
}

.each-slide-effect > Div{
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 150px !important;
}

.menu-div {
    width: 250px;
    min-height: 35px;
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    border-radius: 50px;
}

.menu-div h3 {
    color: #ffffff;
    font-size: 18px;
}

.filter-search{
    text-align: center;
}
}