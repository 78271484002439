@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: Roboto Mono;
}

.hm-bdy{
    display: flex;
    justify-content: center;
}

a{
    text-decoration: none;
}

.enquiry-bdy{
    padding: 150px;
}

.enquiry-card{
    max-width: 1010px;
    box-shadow: 0 0 12px 2px rgb(0 0 0 / 10%);
    margin: auto;
    padding: 20px;
}

.addres{
    min-height: 150px;
}

.descrp{
    min-height: 350px;
}

.enquiry-btn{
    padding: 20px;
}

.send-btn{
    width: 150px;
    min-height: 35px;
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffffff;
    font-size: 22px;
    outline: 0;
    border: #ffffff;
    border-radius: 5px;
    margin: auto;
}

.enquiry-h2{
    font-size: 35px;
    color: crimson;
    font-weight: 800;
    text-align: center;
    margin: 20px 0px;
}

@media (min-width: 320px) and (max-width: 650px){
    
    .hm-bdy{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}