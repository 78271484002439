@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.headerbar {
  min-height: 35px;
  background-color: lightgrey;
  padding: 10px 0px;
}

.headerbar h2 {
  text-align: center;
  font-size: 15px;
  font-family: Roboto Mono;
  color: crimson;
}

.headerbar1 {
  min-height: 65px;
  background-color: #ffffff;
  padding: 5px 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerbar2 {
  min-height: 55px;
  background-color: lightgrey;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-div {
  width: 250px;
  min-height: 35px;
  background-color: crimson;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.menu-bar {
    background-color: #ffffff;
  z-index: 1;
  position: absolute;
  top: 35px;
  left: 0px;
  width: 250px;
  box-shadow: 0 0 12px 2px rgb(0 0 0 / 10%);
}

.menu-li{
    text-decoration: none;
    list-style: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    border-bottom: 1px solid rgb(219, 217, 217);
    padding: 10px;
}

.menu-div h3 {
  color: #ffffff;
  font-size: 22px;
}

.biogenelogo {
  max-width: 200px;
  width: 100%;
}

.headerright {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.signdiv {
  margin-right: 20px;
  text-align: end;
  position: relative;
}

.signdivp {
  font-family: Roboto Mono;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  color: gray;
}

.headsearch {
  /* max-width: 420px;
    width: 100%;
    min-height: 40px;
    background-color: #FFFFFF;
    border-radius: 50px;
    border: 1px solid crimson; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.signpop {
  width: 150px;
  min-height: 120px;
  background-color: #ffffff;
  padding: 10px;
  position: absolute;
  left: -70px;
  z-index: 1;
  box-shadow: 0 2px 8px 0 black;
}

.signpop1 {
  text-align: left;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.search-form {
  display: flex;
}

.hsearch1 {
  -webkit-appearance: none;
          appearance: none;
  border-radius: 50px;
  width: 650px;
  min-height: 40px;
  position: relative;
  border: 2px solid crimson;
  padding: 0 40px 0 20px;
  outline: 0;
  font-size: 18px;
  font-weight: 400;
}

.hsearchicon {
  background-image: url(/static/media/search_FILL0_wght600_GRAD200_opsz48.b4a36d31.svg);
  width: 40px;
  height: 38px;
  background-color: crimson;
  border-radius: 0px 50px 50px 0px;
  background-size: 40px;
  cursor: pointer;
  margin-left: -40px;
  z-index: 1;
}



@media (min-width: 320px) and (max-width: 650px) {
    .hsearch1 {
        -webkit-appearance: none;
                appearance: none;
        border-radius: 50px;
        width: 300px;
        min-height: 40px;
        position: relative;
        border: 2px solid crimson;
        padding: 0 40px 0 20px;
        outline: 0;
        font-size: 18px;
        font-weight: 400;
        margin: 20px 0;
      }

      .hsearchicon {
        margin: 20px 0 20px -40px;
    }

.headerbar1 {
    min-height: 65px;
    background-color: #ffffff;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.biogenelogo {
    max-width: 120px;
    width: 100%;
}

.headerbar2 {
    min-height: 55px;
    background-color: lightgrey;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
}

.each-slide-effect > Div{
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 150px !important;
}

.menu-div {
    width: 250px;
    min-height: 35px;
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    border-radius: 50px;
}

.menu-div h3 {
    color: #ffffff;
    font-size: 18px;
}

.filter-search{
    text-align: center;
}
}
.input1{
    width: 100%;
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 20px;
    outline: 0;
}
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: Roboto Mono;
}

.hm-bdy{
    display: flex;
    justify-content: center;
}

a{
    text-decoration: none;
}

.enquiry-bdy{
    padding: 150px;
}

.enquiry-card{
    max-width: 1010px;
    box-shadow: 0 0 12px 2px rgb(0 0 0 / 10%);
    margin: auto;
    padding: 20px;
}

.addres{
    min-height: 150px;
}

.descrp{
    min-height: 350px;
}

.enquiry-btn{
    padding: 20px;
}

.send-btn{
    width: 150px;
    min-height: 35px;
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffffff;
    font-size: 22px;
    outline: 0;
    border: #ffffff;
    border-radius: 5px;
    margin: auto;
}

.enquiry-h2{
    font-size: 35px;
    color: crimson;
    font-weight: 800;
    text-align: center;
    margin: 20px 0px;
}

@media (min-width: 320px) and (max-width: 650px){
    
    .hm-bdy{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 650px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
.item_body{
    padding: 50px 50px 50px 0px;
    display: flex;
}

.fltline{
    border-right: 0.5px solid rgb(221, 220, 220);
}

.type-grp{
    margin-bottom: 40px;
    padding-left: 50px;
}

.item_grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px 50px;
    gap: 25px 50px;
    margin: auto;
    padding-left: 50px;
}

.item_list{
    width: 250px;
    min-height: 220px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 0 12px 2px rgb(0 0 0 / 10%);
    padding: 20px;
    text-align: center;
}

.item_img{
    width: 220px;
    height: 180px;
    margin-bottom: 20px;
}

.item-logo{
    width: 220px;
    height: 180px;
    margin-bottom: 20px;
}

.item-name{
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
}

.item-code{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: gray;
}

.item-brand{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.item-rate{
    font-size: 20px;
    font-weight: 600;
    color: crimson;
}

.type-select-lable{
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
}

select{
    -webkit-appearance: none;
            appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1px 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: 0;
}

select::-ms-expand {
    display: none;
  }

  .type-select {
    width: 100%;
    min-width: 350px;
    max-width: 50px;
    border: 1px solid crimson;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    color: gray;
    cursor: pointer;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }


@media (min-width: 320px) and (max-width: 650px){
    
    .item_grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 25px 50px;
        gap: 25px 50px;
        margin: auto;
        padding-left: 0px;
    }

    .item_body {
        padding: 0px;
        display: flex;
        margin: auto;
    }
}
.filter-body{
    padding: 50px 15px;
    align-items: left;
}

.flsrch{
    border: 2px solid crimson;
    border-radius: 5px;
    height: 40px;
    width: 200px;
    outline: 0;
    padding: 10px;
    font-size: 20px;
    color: gray;
    margin-bottom: 30px;
}

.flr-br-line{
    border-bottom: 0.5px solid rgb(207, 205, 205);
}

.filter-brand h3{
    margin: 20px 0 20px 0;
}

.fltr-grp{
    line-height: 40px;
    display: flex;
    align-items: center;
}

.fltr-grp input{
    margin-right: 20px;
    width: 25px;
    height: 25px;
    border: 2px solid gray;
    outline: 0;
}

.fltr-grp label{
    font-size: 18px;
    font-weight: 600;
}
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

